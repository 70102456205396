import * as api from "./app_api";
import * as flash from "./flash_helper";

console.log('mobile_api_groups.js : loaded');

function formatOcnGroupTrafficStatus(target, data) {
  target.insertAdjacentHTML('beforeend', `
    <div class="chapter">各種状態</div>
    <table class="values">
      <tbody>
        <tr>
          <td class="title">クーポンON/OFF状態</td>
          <td class="value">${data.couponOnOff}</td>
        </tr>
        <tr>
          <td class="title">ヘビーユーザ監視規制状態</td>
          <td class="value">${data.heavyUserMonitorStatus}</td>
        </tr>
        <tr>
          <td class="title">ヘビーユーザ監視規制開始時間(月間)</td>
          <td class="value">${data.heavyUserMonitorStartTimeMonth}</td>
        </tr>
        <tr>
          <td class="title">ヘビーユーザ監視規制開始時間(直近)</td>
          <td class="value">${data.heavyUserMonitorStartTimeLatest}</td>
        </tr>
      </tbody>
    </table>
  `);
}

function formatOcnGroupTrafficAdditionalCoupons(target, data) {
  let htmlString = `<div class="chapter">追加クーポン</div>`;
  htmlString += `<table class="values"><tbody>`;
  for (let i = 1; i <= 5; i++) {
    const name = `additionalCoupon${i}`;
    const name_a = `additionalCoupon${i}RemainCapacity`;
    const name_b = `additionalCoupon${i}TermValidity`;
    htmlString += `
      <tr>
        <td class="title">追加クーポン${i}残容量</td>
        <td class="value">${data[name][name_a].length > 0 ? Number(data[name][name_a]).toLocaleString() : ""}</td>
      </tr>
      <tr>
        <td class="title">追加クーポン${i}有効期限</td>
        <td class="value">${data[name][name_b]}</td>
      </tr>
    `;
  }
  htmlString += `</tbody></table>`;
  target.insertAdjacentHTML('beforeend', htmlString);
}

function formatOcnGroupTrafficCarryingOverCoupons(target, data) {
  let htmlString = `<div class="chapter">繰越クーポン</div>`;
  htmlString += `<table class="values"><tbody>`;
  for (let i = 1; i <= 2; i++) {
    const name = `carryingOverCoupon${i}`;
    const name_a = `carryingOverCoupon${i}RemainCapacity`;
    const name_b = `carryingOverCoupon${i}TermValidity`;
    htmlString += `
      <tr>
        <td class="title">繰越クーポン${i}残容量</td>
        <td class="value">${data[name][name_a].length > 0 ? Number(data[name][name_a]).toLocaleString() : ""}</td>
      </tr>
      <tr>
        <td class="title">繰越クーポン${i}有効期限</td>
        <td class="value">${data[name][name_b]}</td>
      </tr>
    `;
  }
  htmlString += `</tbody></table>`;
  target.insertAdjacentHTML('beforeend', htmlString);
}

function formatOcnGroupTrafficPastDaily(target, data) {
  let htmlString = `<div class="chapter">日次通信量</div>`;
  htmlString += `<table class="values"><tbody>`;
  htmlString += `
    <tr>
      <td class="title">今日の通信量</td>
      <td class="value">${Number(data.trafficOneDay).toLocaleString()}</td>
    </tr>
  `;
  for (let d = 1; d <= 30; d++) {
    let name = `traffic${d}daysAgo`;
    if (d == 1) {
      name = `traffic${d}dayAgo`;
    }
    htmlString += `
      <tr>
        <td class="title">${d}日前の通信量</td>
        <td class="value">${Number(data[name]).toLocaleString()}</td>
      </tr>
    `;
  }
  htmlString += `</tbody></table>`;
  target.insertAdjacentHTML('beforeend', htmlString);
}

function formatOcnGroupTrafficPastMonthly(target, data) {
  target.insertAdjacentHTML('beforeend', `
    <div class="chapter">月次通信量</div>
    <table class="values">
      <tbody>
        <tr>
          <td class="title">今月の通信量</td>
          <td class="value">${Number(data.trafficThisMonth).toLocaleString()}</td>
        </tr>
        <tr>
          <td class="title">前月の通信量</td>
          <td class="value">${Number(data.trafficPreviousMonth).toLocaleString()}</td>
        </tr>
        <tr>
          <td class="title">前々月の通信量</td>
          <td class="value">${Number(data.trafficBeforehandMonth).toLocaleString()}</td>
        </tr>
      </tbody>
    </table>
  `);
}

function formatOcnGroupTrafficCurrent(target, data) {
  target.insertAdjacentHTML('beforeend', `
    <div class="chapter">概要</div>
    <table class="values">
      <tbody>
        <tr>
          <td class="title">今日の通信量</td>
          <td class="value">${Number(data.trafficOneDay).toLocaleString()}</td>
        </tr>
        <tr>
          <td class="title">今月の通信量</td>
          <td class="value">${Number(data.trafficThisMonth).toLocaleString()}</td>
        </tr>
        <tr>
          <td class="title">基本クーポン残容量</td>
          <td class="value">${Number(data.basicCouponRemains).toLocaleString()}</td>
        </tr>
        <tr>
          <td class="title">基本クーポン有効期限</td>
          <td class="value">${data.basicCouponTermValidity}</td>
        </tr>
        <tr>
          <td class="title">総量規制状態</td>
          <td class="value">${data.totalVolumeControlStatus}</td>
        </tr>
      </tbody>
    </table>
  `);
}

function showOcnGroupError(type, error) {
  removeOcnGroupTraffic();
  const el = document.getElementById('result_mobile_api_group_traffic');
  if (!el) {
    return;
  }
  el.insertAdjacentHTML('beforeend', `
    <div style="color: $${type}">${error}<div>
  `);
}

function showOcnGroupTraffic(data) {
  removeOcnGroupTraffic();
  const el = document.getElementById('result_mobile_api_group_traffic');
  if (!el) {
    return;
  }
  formatOcnGroupTrafficCurrent(el, data);
  formatOcnGroupTrafficPastMonthly(el, data);
  formatOcnGroupTrafficPastDaily(el, data);
  formatOcnGroupTrafficCarryingOverCoupons(el, data);
  formatOcnGroupTrafficAdditionalCoupons(el, data);
  formatOcnGroupTrafficStatus(el, data);
}

function removeOcnGroupTraffic() {
  const el = document.getElementById('result_mobile_api_group_traffic');
  if (!el) {
    return;
  }
  const elsAry = Array.from(el.children);
  elsAry.forEach((child) => {
    child.remove();
  });
}

document.addEventListener('turbolinks:load', () => {
  console.log("load for update_from_ocn_all");
  const el = document.getElementById('result_mobile_api_group_traffic');
  if (!el) {
    return;
  }
  const data = JSON.parse(el.getAttribute('data'));
  api.requestAPI(
    'get', 
    `/mobile_api_groups/${data.id}/traffic`, 
    {}, 
    (response) => {
      flash.showFlash('info', response.message);
      showOcnGroupTraffic(response.data);
    }, 
    (response) => {
      flash.showFlash('danger', response.error);
      showOcnGroupError('danger', response.error);
    }
  );
});
