// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("bootstrap/dist/js/bootstrap")

import "./flash_helper"
import "./app_api"
import "./ocn_util"
import "./view/sims/index"
import "./sims"
import "./mobile_api_groups"

import "controllers"
