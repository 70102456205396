export function get_csrf() {
  const el_meta_csrf = document.querySelector('meta[name="csrf-token"]');
  return el_meta_csrf ? el_meta_csrf.content : null;
}

export function requestAPI(
    method,
    path,
    param,
    fn_success,
    fn_error
  ) {
    const url = location.origin + path;
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("X-CSRF-Token", get_csrf());
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        const response = JSON.parse(xhr.response);
        if (response.error) {
          console.log(`[WARNING] : request failed error=${response.error} [${path}]`);
          if (fn_error) {
            fn_error(response);
          }
        }
        else {
          // success
          console.log(`[SUCCESS] : requestAPI successfully done [${path}]`);
          if (fn_success) {
            fn_success(response);
          }
        }
      } else if (xhr.readyState === XMLHttpRequest.DONE) {
        console.log(`[FAILED] : request failed status=${xhr.status} [${path}]`);
        let response = null;
        try {
          response = JSON.parse(xhr.response);
        } 
        catch (e) {
          console.log(`[FAILED] : Invalid response`, e);
          response = {
            data: null,
            error: "The response is not valid JSON data.",
            message: "[Error] The response is not valid JSON data.",
            status: "Unknown"
          };
        }
        if (fn_error) {
          fn_error(response);
        }
      }
    };
    console.log(param);
    xhr.send(JSON.stringify(param));
  }