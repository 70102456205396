import * as api from "./app_api";
import * as flash from "./flash_helper";

console.log('sims.js : loaded');

function updateSimSelectAll() {
  const all_el = document.getElementById('sim_select_all');
  const els = document.getElementsByName('sim_select');
  const els_ary = Array.from(els);
  let checkedCount = 0;
  els_ary.forEach(el => {
    if (el.checked) {
      checkedCount++;
    }
  });
  if (els_ary.length === checkedCount) {
    if (checkedCount > 0) {
      // all checked
      all_el.indeterminate = false;
      all_el.checked = true;
    }
    else {
      // no items
      all_el.indeterminate = false;
      all_el.checked = false;
    }
  }
  else {
    if (checkedCount > 0) {
      // some items are checked
      all_el.indeterminate = true;
      all_el.checked = true;
    }
    else {
      // no checked
      all_el.indeterminate = false;
      all_el.checked = false;
    }
  }
}

function updateSimsBySelectAll() {
  const all_el = document.getElementById('sim_select_all');
  const els = document.getElementsByName('sim_select');
  const elsAry = Array.from(els);
  elsAry.forEach(el => {
    el.checked = all_el.checked;
  });
}

function checkedSimIds() {
  const els = document.getElementsByName('sim_select');
  const elsAry = Array.from(els);
  const selecting_sims = [];
  elsAry.forEach(el => {
    if (el.checked) {
      selecting_sims.push(el.getAttribute('sim_id'));
    }
  });
  if (selecting_sims.length > 0) {
    return selecting_sims;
  }
  return null;
}

function updateSpecifiedField(tr, key, value) {
  const el = tr.querySelector(`td[name="${key}"]`);
  if (el) {
    el.innerText = value;
  }
}

function updateSpecifiedRecord(record) {
  const el = document.getElementById(`sim_${record.id}`);
  if (el) {
    for (let key in record) {
      if (key !== 'id') {
        updateSpecifiedField(el, key, record[key]);
        console.log(`updateSpecifiedRecord(${record.id}) : ${key}=${record[key]}`);
      }
    }
  }
}

document.addEventListener('turbolinks:load', () => {
  // OCN登録情報から更新
  const els = document.getElementById('get_sim_info_all');
  if (!els) {
    return;
  }
  els.addEventListener('click', () => {
    const ids = checkedSimIds();
    api.requestAPI(
      'post',
      '/sims/update_from_ocn_all',
      {
        ids: ids
      },
      (response) => {
        flash.showFlash('info', response.message);
        console.log('update_from_ocn_all(success)', response);
        response.data.forEach((record) => {
          updateSpecifiedRecord(record);
        });
      },
      (error) => {
        flash.showFlash('danger', error.error);
        console.log('update_from_ocn_all(fail)', error);
      }
    );
  });

  // 選択 (一覧全体)
  const all_sims_el = document.getElementById('sim_select_all');
  if (all_sims_el) {
    all_sims_el.addEventListener('change', () => {
      updateSimsBySelectAll();
    });
  }

  // 選択 (行ごと)
  const sim_els = document.getElementsByName('sim_select');
  const sim_els_ary = Array.from(sim_els);
  sim_els_ary.forEach(el => {
    el.addEventListener('change', () => {
      updateSimSelectAll();
    });
  });

  // プランの予約(一括)
  const show_multi_reservation_el = document.getElementById('show_multi_reservation_dialog');
  if (show_multi_reservation_el) {
    show_multi_reservation_el.addEventListener('click', () => {
      console.log("sims.js::show_multi_reservation_el clicked");
      const plan_multi_reservation_dialog_el = document.getElementById('plan_multi_reservation_dialog');
      if (plan_multi_reservation_dialog_el) {
        plan_multi_reservation_dialog_el.style.display = 'block';
      }
    });
  }

  // プランの予約(一括) - キャンセル
  const btn_cancel_plan_multi_reservation_el = document.getElementById('btn_cancel_plan_multi_reservation');
  if (btn_cancel_plan_multi_reservation_el) {
    btn_cancel_plan_multi_reservation_el.addEventListener('click', () => {
      console.log("sims.js::btn_cancel_plan_multi_reservation_el clicked");
      const plan_multi_reservation_dialog_el = document.getElementById('plan_multi_reservation_dialog');
      if (plan_multi_reservation_dialog_el) {
        plan_multi_reservation_dialog_el.style.display = 'none';
      }
    });
  }

  function dangerSleep(msec) {
    let start_msec = new Date();
    while (new Date() - start_msec < msec);
  }

  // プランの予約(一括) - 実行
  const btn_do_plan_multi_reservation_el = document.getElementById('btn_do_plan_multi_reservation');
  if (btn_do_plan_multi_reservation_el) {
    btn_do_plan_multi_reservation_el.addEventListener('click', () => {
      console.log("sims.js::btn_do_plan_multi_reservation_el clicked");

      const multiple_sim_next_plan_el = document.getElementById('multiple_sim_next_plan');
      const multiple_next_mobile_api_plan_start_at_el = document.getElementById('multiple_next_mobile_api_plan_start_at');
      const plan_data = JSON.parse(multiple_sim_next_plan_el.children[multiple_sim_next_plan_el.selectedIndex].getAttribute('data'));
      console.log('sims.js::btn_do_plan_multi_reservation_el : selected plan_data',plan_data);
      const succeeds = [];
      const fails = [];
      const ids = checkedSimIds();
      const param = {
        sim_ids: ids,
        next_mobile_plan: `${plan_data['candidate_id']}`,
        next_mobile_api_plan_start_at: `"${multiple_next_mobile_api_plan_start_at_el.value}"`
      }
      api.requestAPI(
        'post',
        `/sims/request_change_plan/`,
        param,
        (response) => {
          if (response.status == true) {
            flash.showFlash('info', response.message);
            console.log(`request change plan(success)`,response); 
          }
          else {
            flash.showFlash('danger', response.message);
            console.log(`request change plan(error)`,response);
          }
        },
        (error) => {
          flash.showFlash('danger', error.message);
          console.log(`request change plan(fail)`, error);
        }
      );

      const plan_multi_reservation_dialog_el = document.getElementById('plan_multi_reservation_dialog');
      if (plan_multi_reservation_dialog_el) {
        plan_multi_reservation_dialog_el.style.display = 'none';
      }
    });
  }
});

